import styled, { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    font-size: 62.5%;
  }
  
  body {
    padding: 0 0 40px;
    font-size: 1.6rem;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 1.2;
  }
  
  p, li, a, button, span, input, textarea {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 1.6rem;
  }
`;

export const theme = {
  color: {
    darkBlue: '#233F84',
    primaryBlue: '#2AC0D4',
    black: '#000000',
    lightblack: '#383838',
    white: '#ffffff',
    lightGrayBackground: '#E5E5E5',
    lightGrayText: '#ABABAB',
    darkGray: '#6B6B6B',
    accordionBackground: '#2E2B26'
  },
  size: {
    desktopHeroHeight: '640px',
  },
  font: {
    shadow: '5px 5px 10px hsla(0, 0%, 0%, 0.15)',
    size: {
      heading: '4rem',
      headingMobile: '4.3rem',
      headingSmall: '2.8rem',
      paragraph: '1.6rem',
      caption: '1.35rem',
      mobileMenu: '2.1rem',
      button: '1.2rem',
      thumbnail: '1.8rem',
      thumbnailSmall: '1.4rem',
      opinionTitle: '2rem',
      opinionAuthorDetails: '1.2rem',
      footerDetailsColumnTitle: '1.6rem',
      accordionTitle: '1.6rem',
      startPageTitle: '5rem'

    },
    family: {
      roboto: '"Roboto", sans-serif',
    },
  },
  mq: {
    tablet: '@media (min-width: 768px)',
    desktop: '@media (min-width: 1024px)',
    bigDesktop: '@media (min-width: 1280px)',
    huge: '@media(min-width: 1440px)',
  },
};

export const SectionWrapper = styled.div`
  padding: 0 20px;

  @media screen and (min-width: 840px) {
    padding: 0 140px;
    max-width: 1200px;
    margin: 0 auto;
  }
`;