import styled from "styled-components";

export const FooterWrapper = styled.div`
    padding: 0 20px;

    @media screen and (min-width: 840px) {
        padding: 0 140px;
    }
`;

export const FooterContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 640px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 840px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const FooterDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.darkGray};
    margin-bottom: 40px;
`;

export const LeftColumnFooter = styled.div`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    justify-content: center;
    text-align: center;

    @media screen and (min-width: 840px) {
        text-align: left;
    }
`;

export const LeftColumnFooterTitle = styled.p`
    margin: 0;
    font-size: ${({ theme }) => theme.font.size.footerDetailsColumnTitle};
    font-weight: 500;
    color: ${({ theme }) => theme.color.darkBlue};
`;

export const LeftColumnFooterDetails = styled.p`
    color: ${({ theme }) => theme.color.lightGrayText};
    margin: 0;
`;

export const CenterColumnFooter = styled.div`
    display: flex;
    justify-content: center;
`;

export const RightColumnFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (min-width: 840px) {
    justify-content: flex-end;
    }
`;