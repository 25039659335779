import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledLogo = styled(GatsbyImage)`
  width: 220px;
  margin: 0 auto;
  order: 1;

  @media screen and (min-width: 840px) {
    margin: 0 20px;
    z-index: 100;
    width: 336px;
    order: initial;
  }
`;