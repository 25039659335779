import React from "react";
import { FacebookIcon } from "../FacebookIcon/FacebookIcon";
import { InstagramIcon } from "../InstagramIcon/InstagramIcon";
import Logo from "../Logo/Logo";
import { YoutubeIcon } from "../YoutubeIcon/YoutubeIcon";
import { CenterColumnFooter, FooterContainer, FooterDivider, FooterWrapper, LeftColumnFooter, LeftColumnFooterDetails, LeftColumnFooterTitle, RightColumnFooter } from "./Footer.styled";

export const Footer = () => (
    <FooterWrapper>
        <FooterDivider />
        <FooterContainer>
            <LeftColumnFooter>
                <LeftColumnFooterTitle>Zostańmy w kontakcie</LeftColumnFooterTitle>
                <LeftColumnFooterDetails>Dawid Mazurek</LeftColumnFooterDetails>
                <LeftColumnFooterDetails>Dave Wave Studio</LeftColumnFooterDetails>
            </LeftColumnFooter>
            <CenterColumnFooter>
                <Logo />
            </CenterColumnFooter>
            <RightColumnFooter>
                <FacebookIcon />
                <InstagramIcon />
                <YoutubeIcon />
            </RightColumnFooter>
        </FooterContainer>
    </FooterWrapper>
)
