import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from "../assets/styles/ThemeConfig";
import '../assets/styles/normalize.css';
import Navbar from '../components/Common/Navbar/Navbar';
import {Footer} from '../components/Common/Footer/Footer';

export const MainTemplate = ({ children }) => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <Navbar />
      <main>
        {children}
      </main>
      <Footer />
    </ThemeProvider>
  </>
);