import styled from "styled-components";
import Instagram from "../../../assets/icons/instagram.svg";
import { Link } from "gatsby";

export const StyledInstagramIcon = styled(Instagram)`
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

export const StyledLink = styled(Link)`

`;