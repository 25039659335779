import styled from "styled-components";
import Facebook from "../../../assets/icons/facebook.svg";
import { Link } from "gatsby";

export const StyledFacebookIcon = styled(Facebook)`
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

export const StyledLink = styled(Link)`

`;