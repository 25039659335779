import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StyledLogo } from './Logo.styled';
import { Link } from 'gatsby';


const query = graphql`
  {
    logo: file(name: {eq: "logo"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
}
`

const Logo = () => {
    const data = useStaticQuery(query);
    
    return <Link to="/"><StyledLogo image={data.logo.childImageSharp.gatsbyImageData} imgStyle={{objectFit: 'contain'}} alt={'Logo DaveWaveStudio'}/></Link>
}

export default Logo;