import styled from "styled-components";
import Youtube from "../../../assets/icons/youtube.svg";
import { Link } from "gatsby";

export const StyledYoutubeIcon = styled(Youtube)`
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

export const StyledLink = styled(Link)`

`;