import React, { useState } from "react";
import { StyledNavbar, StyledUl, StyledLi, StyledHamburgerLogoContainer, HamburgerButton, HamburgerBox, HamburgerInner, StyledButton } from './Navbar.styled';
import Logo from "../Logo/Logo";
import scrollTo from "gatsby-plugin-smoothscroll";

const Navbar = () => {
    const [menu, showMenu] = useState(false);

    const scrollToElementFn = (element) => {
        window.location.href = `./#${element}`;
    };

    if (typeof window !== 'undefined') {
        if (window.location.pathname === '/') {
            return null;
        }
    }

    return (
        <>
        {typeof window !== 'undefined' && window.location.pathname !== '/' &&
            <StyledHamburgerLogoContainer>
                <HamburgerButton
                    hamburger={menu}
                    className={"hamburger"}
                    onClick={() => showMenu(!menu)}
                >
                    <HamburgerBox>
                        <HamburgerInner hamburger={menu} />
                    </HamburgerBox>
                </HamburgerButton>
            </StyledHamburgerLogoContainer>
        }

            <StyledNavbar showNav={menu}>
                <StyledUl>
                    <StyledLi>
                        {typeof window !== 'undefined' && (window.location.pathname === '/produkcja-muzyczna' || window.location.pathname === '/produkcja-muzyczna/') &&
                            <StyledButton onClick={
                                (() => showMenu(!menu),
                                    () =>
                                        (typeof window !== 'undefined' && window.location.href.indexOf("zdjecia") > -1) ||
                                            (typeof window !== 'undefined' && window.location.href.indexOf("video") > -1)
                                            ? scrollToElementFn("about-us-music")
                                            : scrollTo("#about-us-music"))
                            }>O nas</StyledButton>
                        }
                        {typeof window !== 'undefined' && window.location.pathname.indexOf('lekcje-gry-na-gitarze') > -1 &&
                            <StyledButton onClick={
                                (() => showMenu(!menu),
                                    () =>
                                        (typeof window !== 'undefined' && window.location.href.indexOf("zdjecia") > -1) ||
                                            (typeof window !== 'undefined' && window.location.href.indexOf("video") > -1)
                                            ? scrollToElementFn("about-us")
                                            : scrollTo("#about-us"))
                            }>O nas</StyledButton>
                        }
                    </StyledLi>
                    <StyledLi>
                        {typeof window !== 'undefined' && (window.location.pathname === '/produkcja-muzyczna' || window.location.pathname === '/produkcja-muzyczna/') && 
                        <StyledButton onClick={
                            (() => showMenu(!menu),
                                () =>
                                    (typeof window !== 'undefined' && window.location.href.indexOf("zdjecia") > -1) ||
                                        (typeof window !== 'undefined' && window.location.href.indexOf("video") > -1)
                                        ? scrollToElementFn("cooperate")
                                        : scrollTo("#cooperate"))
                        }>Współpraca</StyledButton>
                        }
                        {typeof window !== 'undefined' && window.location.pathname.indexOf('lekcje-gry-na-gitarze') > -1 &&
                            <StyledButton onClick={
                                (() => showMenu(!menu),
                                    () =>
                                        (typeof window !== 'undefined' && window.location.href.indexOf("zdjecia") > -1) ||
                                            (typeof window !== 'undefined' && window.location.href.indexOf("video") > -1)
                                            ? scrollToElementFn("price-list")
                                            : scrollTo("#price-list"))
                            }>Cennik</StyledButton>
                        }
                    </StyledLi>
                    <Logo />
                    <StyledLi>
                        {typeof window !== 'undefined' && (window.location.pathname === '/produkcja-muzyczna' || window.location.pathname === '/produkcja-muzyczna/') &&
                            <StyledButton onClick={
                                (() => showMenu(!menu),
                                    () => scrollTo("#photo-gallery-music"))
                            }>Zdjęcia</StyledButton>
                        }
                        {typeof window !== 'undefined' && window.location.pathname.indexOf('lekcje-gry-na-gitarze') > -1 &&
                            <StyledButton onClick={
                                (() => showMenu(!menu),
                                    () =>
                                        (typeof window !== 'undefined' && window.location.href.indexOf("zdjecia") > -1) ||
                                            (typeof window !== 'undefined' && window.location.href.indexOf("video") > -1)
                                            ? scrollToElementFn("photo-gallery")
                                            : scrollTo("#photo-gallery"))
                            }>Zdjęcia</StyledButton>
                        }
                    </StyledLi>
                    <StyledLi>
                        <StyledButton onClick={
                            (() => showMenu(!menu),
                                () =>
                                    (typeof window !== 'undefined' && window.location.href.indexOf("zdjecia") > -1) ||
                                        (typeof window !== 'undefined' && window.location.href.indexOf("video") > -1)
                                        ? scrollToElementFn("contact")
                                        : scrollTo("#contact"))
                        }>Kontakt</StyledButton>
                    </StyledLi>
                </StyledUl>
            </StyledNavbar>
        </>
    )
}

export default Navbar;