import styled from "styled-components";
import { Link } from 'gatsby';

export const HamburgerInner = styled.span`
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.1s 0.2s ease-in-out;
  width: 100%;
  height: 3px;
  background-color: ${({ hamburger }) =>
    hamburger ? "transparent" : '#2AC0D4'};
  position: absolute;

  &::before,
  &::after {
    content: "";
    left: 0;
    transition: transform 0.2s 0.2s ease-in-out;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.color.primaryBlue};
    position: absolute;
  }

  &::before {
    top: -10px;
    transform: ${({ hamburger }) =>
    hamburger
      ? "translateY(12px) rotate(135deg)"
      : "translateY(0) rotate(0)"};
  }

  &::after {
    top: 10px;
    transform: ${({ hamburger }) =>
    hamburger
      ? "translateY(-8px) rotate(-135deg)"
      : "translateY(0) rotate(0)"};
  }
`;

export const StyledHamburgerLogoContainer = styled.div`
  display: flex;

  @media screen and (min-width: 840px) {
    flex-direction: column;
  }
`;

export const HamburgerButton = styled.button`
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  width: fit-content;
  transition: transform 0.3s 0.1s ease-in-out;
  transform: ${({ hamburger }) =>
    hamburger ? "translateX(250px)" : "translateX(0)"};
  z-index: 100;
  position: fixed;

  @media screen and (min-width: 840px) {
    display: none;
  }
`;

export const HamburgerBox = styled.span`
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

export const StyledNavbar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 5px 7px 18px -16px rgba(66, 68, 90, 1);
    z-index: 99;
    padding: 20px 0;
    transform: ${({ showNav }) =>
    showNav ? "translateX(0px)" : "translateX(-1280px)"};
  transition: transform 0.3s 0.1s ease-in-out;

  @media screen and (min-width: 840px) {
    position: sticky;
    height: 140px;
    width: 100%;
    transform: translateX(0);
  }
`;

export const StyledUl = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 10px;

    @media screen and (min-width: 840px) {
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin: 0;
    }
`;

export const StyledLi = styled.li`
    list-style: none;
    font-weight: 100;
    margin-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.color.darkBlue};

    @media screen and (min-width: 840px) {
      margin-right: 30px;
      border-bottom: none;
      margin-bottom: 0;
    }
`;

export const StyledLink = styled(Link)`
        text-decoration: none;
        color: ${({ theme }) => theme.color.darkBlue};

        &.active {
            color: ${({ theme }) => theme.color.primaryBlue};
        }
`;

export const StyledButton = styled.button`
  margin: 0 auto;
  cursor: pointer;

  -webkit-text-decoration: none;
  text-decoration: none;
  color: ${({ theme }) => theme.color.darkBlue};
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-size: 16px;
  margin: 0;
  outline: none;
  background: none;
  border: none;

  &.active, &:hover {
    color: ${({ theme }) => theme.color.primaryBlue};
  }

  @media screen and (min-width: 840px) {
    margin: 0 20px;
    z-index: 9;
  }
`;